const calendar = document.querySelector('[data-calendar]')
const setDay = (el, winner) => {
  if (!el) {
    return;
  }
  calendar.querySelectorAll('.active')
    .forEach(el => el.classList.remove('active'))
  el.classList.add('active')
  const winEl = document.querySelector('.winner.winner--1')
  winEl.querySelector('.date').innerText = `${el.dataset.day}.${el.dataset.month}.2024`
  if (winner) {
    winEl.classList.add('has-winner')
    winEl.querySelector('.winner-name').innerText = winner
  } else {
    winEl.classList.remove('has-winner')
  }
}
if (calendar) {
  const month = calendar.dataset.month
  const year = calendar.dataset.year
  const winners = JSON.parse(calendar.dataset.winners)
  const todayDate = new Date()
  if (todayDate.getMonth() === 10) {
    const todayEl = calendar.querySelector(`[data-day="${todayDate.getDate()}"][data-month="11"]`)
    const todayYear = todayDate.getFullYear()
    const todayFormattedMonth = todayDate.getMonth() + 1 < 10
      ? `0${todayDate.getMonth() + 1}`
      : todayDate.getMonth() + 1
    const todayFormattedDay = todayDate.getDate() < 10
      ? `0${todayDate.getDate()}`
      : todayDate.getDate()
    const todayFormatted = `${todayYear}-${todayFormattedMonth}-${todayFormattedDay}`
    setDay(todayEl, winners[todayFormatted] || null)
  } else {
    const keys = Object.keys(winners);
    const date = keys[keys.length - 1];
    const dateParts = date.split('-')
    const todayEl = calendar.querySelector(`[data-day="${dateParts[2]}"][data-month="${dateParts[1]}"]`)
    setDay(todayEl, winners[date])
  }
  calendar.querySelectorAll('[data-day]')
    .forEach(el => {
      if (el.dataset.month !== month) {
        return el.classList.add('disabled')
      }
      const key = `${year}-${month}-${el.dataset.day}`
      if (winners[key]) {
        const a = document.createElement('a')
        a.href = '#'
        a.innerText = el.dataset.day
        a.onclick = (e) => {
          e.preventDefault()
          setDay(el, winners[key])
        }
        el.innerHTML = ''
        el.appendChild(a)
      }
    })
  const anchors = document.querySelector('[data-calendar]').querySelectorAll('a')
  if (anchors.length > 0) anchors[anchors.length-1].click()
}
