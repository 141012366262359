document.addEventListener('DOMContentLoaded', function() {

  document.querySelectorAll('textarea[maxlength]').forEach(textarea => {
    // Tworzymy nowy kontener
    const container = document.createElement('div');
    container.className = 'textarea-container';

    // Tworzymy licznik znaków
    const maxLength = textarea.getAttribute('maxlength');
    const charCount = document.createElement('div');
    charCount.className = 'char-count';
    charCount.textContent = `0/${maxLength} znaków`;

    // Opakowujemy textarea w kontener i dodajemy licznik
    textarea.parentNode.insertBefore(container, textarea);
    container.appendChild(textarea);
    container.appendChild(charCount);

    // Dodajemy event listener na textarea, aby aktualizować licznik
    textarea.addEventListener('input', function () {
      const currentLength = textarea.value.length;
      charCount.textContent = `${currentLength}/${maxLength} znaków`;
    });
  });

});
